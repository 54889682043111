import { Form } from "react-bootstrap";
import type { JSONSchema7 } from "json-schema";

const WidgetLabel = ({ id, label, schema, required, rawErrors = [] }: WidgetLabelProps) => {
    return (
        <Form.Label className={rawErrors.length > 0 ? "text-danger" : ""} htmlFor={id}>
            {label || schema.title}
            {(label || schema.title) && required ? <>&thinsp;{"*"}</> : null}
        </Form.Label>
    );
};

type WidgetLabelProps = {
    id: string;
    label: string;
    schema: JSONSchema7;
    required: boolean;
    rawErrors?: string[];
};

export default WidgetLabel;
