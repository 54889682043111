import { isString } from "lodash";
import { v1 as uuidv1 } from "uuid";

export const createId = () => {
    return uuidv1().toUpperCase().split("-").join("");
};

export const removeNonASCIICharacters = (str: string) => str.replace(/[^\x00-\x7F]/g, ""); // eslint-disable-line no-control-regex

/**
 * Check whether a string only contains digits.
 */
export const isDigits = (value: string) => {
    const regEx = new RegExp(/^[0-9]*$/g); // numbers only
    return regEx.test(value);
};

export const stripHtml = (html: string) => {
    var div = document.createElement("div");
    div.innerHTML = html;

    return div.textContent || div.innerText || "";
};

/**
 * Escapes the pipe sign (|) in a string by replacing it with the HTML entity code &#124;.
 */
export const escapePipeSign = (value: unknown) => {
    if (!isString(value)) {
        return value;
    }

    return value.split("|").join("&#124;");
};

/**
 * Replaces all occurrences of "&#124;" with "|" in the given string.
 */
export const unescapePipeSign = (value: unknown) => {
    if (!isString(value)) {
        return value;
    }

    return value.split("&#124;").join("|");
};
