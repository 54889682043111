import Form from "react-bootstrap/Form";
import { Avatar } from "components/Avatar";
import { Button } from "components/Button";
import { Offcanvas } from "react-bootstrap";
import { ContactRequirements } from "types/ContactRequirements";
import { EMPTY_VALUE, getAvatarColor, getAvatarText, getContactName, getContactTypeByCode } from "./utils";
import { capitalize, groupBy, isEmpty, isNil, orderBy, set } from "lodash";
import { SyntheticEvent, useContext, useMemo, useState } from "react";
import { AppContext } from "components/App/AppContext";
import { SubmitButton } from "components/Button/SubmitButton";
import {
    ApplicationContact,
    ContactType,
    ContactData,
    getHiddenFieldsByContactType,
    getRequiredFieldsByContactType,
    isContactDataEmpty,
    ContractorData,
    updateCustomerContact,
    RequiredField,
    createCustomerContact,
} from "components/utils/contacts";
import { RequireContact } from "types/RequireContact";
import {
    REQUIRED_FIELD_ERROR_TEXT,
    REQUIRED_LAST_AND_FIRST_OR_CONTACT,
    isEmail,
    isPhoneNumber,
    isPostalCode,
} from "components/utils/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCustomerContactsV2 } from "components/utils/useCustomerContacts";
import { DEFAULT_DROPDOWN_PLACEHOLDER } from "components/JsonForm/utils";
import { getUrl, httpPutAuthorized } from "components/utils/http";
import { State, useStates } from "components/utils/useStates";
import { ContractorSearch } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/ContractorSearch";
import { ContactTypeCode } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { createAppContact } from "components/utils";
import { useToast } from "components/Toast";
import { ContactSubTitle } from "./ContactSubTitle";
import { ContactTitle } from "./ContactTitle";

const CONTACT_NAME_VALIDATION_INFO_ID = "first-last-company-name-info";

export const ContactEdit = ({
    contact,
    newContact,
    newContactTypeCode,
    applicationNumber,
    isCustomerContact = false,
    isAdditionalContact = false,
    isAppSubmitted = false,
    hideSaveContactSection = false,
    requirements,
    onSaved,
    onClose,
}: {
    contact?: ApplicationContact;
    newContact?: boolean;
    newContactTypeCode?: ContactTypeCode;
    applicationNumber?: string;
    isCustomerContact?: boolean;
    isAdditionalContact?: boolean;
    isAppSubmitted?: boolean;
    hideSaveContactSection?: boolean;
    requirements: ContactRequirements;
    onSaved?: () => void;
    onClose: () => void;
}) => {
    const {
        requirements: { hideAcctNumberOnPortal },
    } = useContext(AppContext);

    const [customerContacts = []] = useCustomerContactsV2(1, 100);
    const [states = []] = useStates();
    const toast = useToast();

    const existingContactListItems = useMemo(
        () =>
            [
                {
                    value: "",
                    label: DEFAULT_DROPDOWN_PLACEHOLDER,
                },
            ].concat(
                orderBy(
                    customerContacts.map((c) => ({
                        value: c.contactNumber,
                        label: getContactNameWithAddress(c),
                    })),
                    (c) => (c.label ?? "").toLowerCase()
                )
            ),
        [customerContacts]
    );

    const contactType = getContactTypeByCode(newContact ? Number(newContactTypeCode) : Number(contact?.contactType));
    const hiddenFields = getHiddenFieldsByContactType(requirements.hiddenFields, contactType);
    const requiredFields = isCustomerContact
        ? requirements.requiredFields.map((field: RequiredField) => field.field)
        : getRequiredFieldsByContactType(requirements.requiredFields, contactType);

    const avatarColor = getAvatarColor(contact);
    const avatarText = getAvatarText(contact);
    const contactTitle = getContactName(contact);

    const [saveContact, setSaveContact] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<ContactValidationResult | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [updatedContact, setUpdatedContact] = useState<Partial<ApplicationContact>>(contact ?? {});

    const contactSubTitle = isAdditionalContact ? contact?.contactTitle ?? EMPTY_VALUE : capitalize(contactType) + " Contact";

    const showContractorSearch =
        contactType === ContactType.Contractor &&
        requirements.requireContractor &&
        [RequireContact.RequiredFromList, RequireContact.NotRequiredFromList].includes(requirements.requireContractor) &&
        !isNil(applicationNumber);

    const isContractorRequiredFromList =
        (!isAdditionalContact &&
            requirements.requireContractor &&
            [RequireContact.RequiredFromList].includes(requirements.requireContractor)) ||
        (isAdditionalContact && !requirements.disableAddlContactValidation);

    const showStoredContactSelection = !showContractorSearch && contact;
    const showValidationInfo = !showContractorSearch;
    const isFormReadOnly =
        (contactType === ContactType.Contractor &&
            requirements.requireContractor &&
            [RequireContact.ReadOnly].includes(requirements.requireContractor)) ||
        showContractorSearch;
    const showSaveContactSection = !(isFormReadOnly || hideSaveContactSection);

    const onChange = (key: string, value: string) => {
        setUpdatedContact({ ...updatedContact, [key]: value });
    };

    const onSelectStoredContact = (e: SyntheticEvent<HTMLSelectElement, Event>) => {
        if (!contact) return;

        const contactNumber = (e.target as HTMLSelectElement).value;
        const storedContact = customerContacts.find((c) => c.contactNumber === contactNumber);

        if (storedContact) {
            if (isAdditionalContact) {
                setUpdatedContact({
                    ...storedContact,
                    contactNumber: contact.contactNumber,
                    storedContactNumber: storedContact.contactNumber,
                });
            } else {
                setUpdatedContact({
                    ...storedContact,
                    contactType: contact.contactType,
                    contactTitle: contact.contactTitle,
                    contactNumber: contact.contactNumber,
                    storedContactNumber: storedContact.contactNumber,
                });
            }
        }
    };

    const onSelectContractor = (contractor: ContractorData) => {
        // map selected contractor to app contact
        const contractorContact: ApplicationContact = {
            contactType: String(ContactTypeCode.Contractor),
            contactTitle: getContactTitle(ContactType.Contractor, contractor.contactTitle, isAdditionalContact),
            firstName: contractor.firstName ?? "",
            lastName: contractor.lastName ?? "",
            accountNumber: contractor.acct_number ?? "",
            address: contractor.address ?? "",
            addressCont: contractor.address_cont ?? "",
            city: contractor.city ?? "",
            state: contractor.state ?? "",
            zip: contractor.zip ?? "",
            phone: contractor.phone ?? "",
            cell: contractor.cell ?? "",
            fax: contractor.fax ?? "",
            company: contractor.company ?? "",
            email: contractor.email ?? "",
            taxId: contractor.taxId ?? "",
            premiseId: contractor.premiseId ?? "",
            meterId: contractor.meterId ?? "",
            contactNumber: contractor.contactNumber ?? "",
            origId: contractor.origId,
        };

        setUpdatedContact(contractorContact);
    };

    const onSaveContact = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsSubmitting(true);
        setFieldErrors(null);

        try {
            if (!isCustomerContact && !applicationNumber) {
                throw new Error("No application number provided");
            }

            // Validate fields
            const errors = validateContactData({
                contact: updatedContact as ApplicationContact,
                contactType,
                requirements,
                isCustomerContact,
                isAppSubmitted,
            });

            if (isEmpty(errors)) {
                // Save contact

                // Using 'undefined' here to avoid sending empty string to the API
                const contactData: ContactData = {
                    contactType: Number(newContactTypeCode ?? updatedContact.contactType),
                    contactTitle: getContactTitle(contactType, updatedContact.contactTitle, isAdditionalContact),
                    firstName: updatedContact.firstName || undefined,
                    lastName: updatedContact.lastName || undefined,
                    acct_number: updatedContact.accountNumber || undefined,
                    address: updatedContact.address,
                    address_cont: updatedContact.addressCont || undefined,
                    city: updatedContact.city,
                    state: updatedContact.state,
                    zip: updatedContact.zip,
                    phone: updatedContact.phone || undefined,
                    cell: updatedContact.cell || undefined,
                    fax: updatedContact.fax || undefined,
                    company: updatedContact.company || undefined,
                    email: updatedContact.email || undefined,
                    taxId: updatedContact.taxId || undefined,
                    premiseId: updatedContact.premiseId || undefined,
                    meterId: updatedContact.meterId || undefined,
                    contactNumber: updatedContact.contactNumber,
                    applicationContactType: contactType,
                    storedContactNumber: updatedContact.storedContactNumber,
                    workflowTargetGroupId: undefined, // TODO: When this is needed?
                    customerNumber: undefined, // TODO: When this is needed?
                    refappid: undefined, // TODO: When this is needed?
                    refid: undefined, // TODO: When this is needed?
                    saveContact,
                };

                let response;
                if (isCustomerContact) {
                    if (!updatedContact.contactNumber) {
                        response = await createCustomerContact(contactData);
                    } else {
                        response = await updateCustomerContact(contactData, updatedContact.contactNumber);
                    }
                } else if (updatedContact.contactNumber && applicationNumber) {
                    response = await updateAppContact(applicationNumber, updatedContact.contactNumber, contactData);
                } else {
                    response = await createAppContact(applicationNumber, contactData);
                }

                setIsSubmitting(false);
                toast.success(response.responseMessage);

                // Refresh contact list
                onSaved?.();

                // Close sidebar
                onClose();
            } else {
                setIsSubmitting(false);
                setFieldErrors(errors);

                // Focus first error
                setTimeout(() => {
                    const firstError = document.querySelector(".is-invalid") as HTMLElement;
                    firstError?.focus();
                }, 100);
            }
        } catch (err) {
            console.error(err);
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas className="contact-details-sidebar" show={true} placement="end" onHide={onClose} aria-labelledby="contact-edit-title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="contact-edit-title" className="fs-4">
                    {newContact ? "Add Contact" : "Contact Information"}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column gap-3">
                {!newContact && (
                    <>
                        <div className="d-flex align-items-center align-self-stretch gap-2 lh-base">
                            <Avatar color={avatarColor} text={avatarText} />
                            <div className="d-flex flex-column">
                                <ContactSubTitle>{contactSubTitle}</ContactSubTitle>
                                <ContactTitle>{contactTitle}</ContactTitle>
                            </div>
                        </div>
                        <hr className="my-0"></hr>
                    </>
                )}
                {/* Stored contact selection */}
                {showStoredContactSelection && (
                    <Form.Group controlId="storedContact">
                        <Form.Label>Choose existing contact</Form.Label>
                        <Form.Select onChange={onSelectStoredContact}>
                            {existingContactListItems.map((c) => (
                                <option key={c.value} value={c.value}>
                                    {c.label}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}

                {/* Contractor search */}
                {showContractorSearch && (
                    <ContractorSearch
                        applicationNumber={applicationNumber}
                        hide={!showContractorSearch}
                        onSelect={onSelectContractor}
                        isContractorRequired={isContractorRequiredFromList}
                    />
                )}

                {/* Contact title and type for new contacts */}
                {newContact && !newContactTypeCode && (
                    <>
                        <Form.Group controlId="contactType">
                            <Form.Label>
                                Choose contact type
                                <RequiredFieldIndicator required />
                            </Form.Label>
                            <Form.Select
                                value={updatedContact.contactType ?? ""}
                                isInvalid={Boolean(fieldErrors?.contactType)}
                                disabled={isFormReadOnly}
                                onChange={(e) => onChange("contactType", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.contactType) ? "state-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.contactType)}
                                required={true}
                                aria-required={true}
                                autoComplete="off"
                            >
                                <option value="">{DEFAULT_DROPDOWN_PLACEHOLDER}</option>
                                <option value={ContactTypeCode.Primary}>Primary</option>
                                <option value={ContactTypeCode.Premise}>Premise</option>
                                <option value={ContactTypeCode.Contractor}>Contractor</option>
                            </Form.Select>
                            {fieldErrors?.contactType && (
                                <Form.Control.Feedback id="contactType-error" type="invalid">
                                    {fieldErrors.contactType}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="contactTitle">
                            <Form.Label>Name this contact</Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.contactTitle ?? ""}
                                isInvalid={Boolean(fieldErrors?.contactTitle)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("contactTitle", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.contactTitle) ? "contactTitle-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.contactTitle)}
                                autoComplete="off"
                            />
                            {fieldErrors?.contactTitle && (
                                <Form.Control.Feedback id="contactTitle-error" type="invalid">
                                    {fieldErrors.contactTitle}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </>
                )}

                {/* Validation info message */}
                {showValidationInfo && (
                    <div id={CONTACT_NAME_VALIDATION_INFO_ID} className="d-flex flex-row gap-2 small">
                        <span>
                            <FontAwesomeIcon icon="circle-info" />
                        </span>
                        <span>{REQUIRED_LAST_AND_FIRST_OR_CONTACT}</span>
                    </div>
                )}

                <Form className="d-flex flex-column gap-3" noValidate onSubmit={onSaveContact}>
                    {!hiddenFields.includes("firstname") && (
                        <Form.Group controlId="firstName">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.firstName ?? ""}
                                isInvalid={Boolean(fieldErrors?.firstName)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("firstName", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.firstName) ? "firstName-error" : CONTACT_NAME_VALIDATION_INFO_ID}
                                aria-invalid={Boolean(fieldErrors?.firstName)}
                                autoComplete="given-name"
                            />
                            {fieldErrors?.firstName && (
                                <Form.Control.Feedback id="firstName-error" type="invalid">
                                    {fieldErrors.firstName}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("lastname") && (
                        <Form.Group controlId="lastName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.lastName ?? ""}
                                isInvalid={Boolean(fieldErrors?.lastName)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("lastName", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.lastName) ? "lastName-error" : CONTACT_NAME_VALIDATION_INFO_ID}
                                aria-invalid={Boolean(fieldErrors?.lastName)}
                                autoComplete="family-name"
                            />
                            {fieldErrors?.lastName && (
                                <Form.Control.Feedback id="lastName-error" type="invalid">
                                    {fieldErrors.lastName}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("company") && (
                        <Form.Group controlId="company">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.company ?? ""}
                                isInvalid={Boolean(fieldErrors?.company)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("company", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.company) ? "company-error" : CONTACT_NAME_VALIDATION_INFO_ID}
                                aria-invalid={Boolean(fieldErrors?.company)}
                                autoComplete="organization"
                            />
                            {fieldErrors?.company && (
                                <Form.Control.Feedback id="company-error" type="invalid">
                                    {fieldErrors.company}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("email") && (
                        <Form.Group controlId="email">
                            <Form.Label>
                                Email
                                <RequiredFieldIndicator required={requiredFields.includes("email")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.email ?? ""}
                                isInvalid={Boolean(fieldErrors?.email)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("email", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.email) ? "email-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.email)}
                                required={requiredFields.includes("email")}
                                aria-required={requiredFields.includes("email")}
                                autoComplete="email"
                            />
                            {fieldErrors?.email && (
                                <Form.Control.Feedback id="email-error" type="invalid">
                                    {fieldErrors.email}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("phone") && (
                        <Form.Group controlId="phone">
                            <Form.Label>
                                Phone
                                <RequiredFieldIndicator required={requiredFields.includes("phone")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.phone ?? ""}
                                isInvalid={Boolean(fieldErrors?.phone)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("phone", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.phone) ? "phone-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.phone)}
                                required={requiredFields.includes("phone")}
                                aria-required={requiredFields.includes("phone")}
                                autoComplete="tel"
                            />
                            {fieldErrors?.phone && (
                                <Form.Control.Feedback id="phone-error" type="invalid">
                                    {fieldErrors.phone}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("cell") && (
                        <Form.Group controlId="cell">
                            <Form.Label>
                                Cell
                                <RequiredFieldIndicator required={requiredFields.includes("cell")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.cell ?? ""}
                                isInvalid={Boolean(fieldErrors?.cell)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("cell", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.cell) ? "cell-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.cell)}
                                required={requiredFields.includes("cell")}
                                aria-required={requiredFields.includes("cell")}
                                autoComplete="tel"
                            />
                            {fieldErrors?.cell && (
                                <Form.Control.Feedback id="cell-error" type="invalid">
                                    {fieldErrors.cell}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("fax") && (
                        <Form.Group controlId="fax">
                            <Form.Label>
                                Fax
                                <RequiredFieldIndicator required={requiredFields.includes("fax")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.fax ?? ""}
                                isInvalid={Boolean(fieldErrors?.fax)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("fax", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.fax) ? "fax-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.fax)}
                                required={requiredFields.includes("fax")}
                                aria-required={requiredFields.includes("fax")}
                                autoComplete="tel"
                            />
                            {fieldErrors?.fax && (
                                <Form.Control.Feedback id="fax-error" type="invalid">
                                    {fieldErrors.fax}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}

                    <hr className="my-0"></hr>

                    {!hiddenFields.includes("address") && (
                        <Form.Group controlId="address">
                            <Form.Label>
                                Address
                                <RequiredFieldIndicator required={requiredFields.includes("address")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.address ?? ""}
                                isInvalid={Boolean(fieldErrors?.address)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("address", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.address) ? "address-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.address)}
                                required={requiredFields.includes("address")}
                                aria-required={requiredFields.includes("address")}
                                autoComplete="street-address"
                            />
                            {fieldErrors?.address && (
                                <Form.Control.Feedback id="address-error" type="invalid">
                                    {fieldErrors.address}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("address_cont") && (
                        <Form.Group controlId="address_cont">
                            <Form.Label>
                                Address (cont)
                                <RequiredFieldIndicator required={requiredFields.includes("address_cont")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.addressCont ?? ""}
                                isInvalid={Boolean(fieldErrors?.addressCont)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("addressCont", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.addressCont) ? "address_cont-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.addressCont)}
                                required={requiredFields.includes("address_cont")}
                                aria-required={requiredFields.includes("address_cont")}
                                autoComplete="street-address"
                            />
                            {fieldErrors?.addressCont && (
                                <Form.Control.Feedback id="address_cont-error" type="invalid">
                                    {fieldErrors.addressCont}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("city") && (
                        <Form.Group controlId="city">
                            <Form.Label>
                                City
                                <RequiredFieldIndicator required={requiredFields.includes("city")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.city ?? ""}
                                isInvalid={Boolean(fieldErrors?.city)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("city", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.city) ? "city-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.city)}
                                required={requiredFields.includes("city")}
                                aria-required={requiredFields.includes("city")}
                                autoComplete="address-level2"
                            />
                            {fieldErrors?.city && (
                                <Form.Control.Feedback id="city-error" type="invalid">
                                    {fieldErrors.city}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("state") && (
                        <Form.Group controlId="state">
                            <Form.Label>
                                State/Province
                                <RequiredFieldIndicator required={requiredFields.includes("state")} />
                            </Form.Label>
                            <Form.Select
                                value={updatedContact.state ?? ""}
                                isInvalid={Boolean(fieldErrors?.state)}
                                disabled={isFormReadOnly}
                                onChange={(e) => onChange("state", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.state) ? "state-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.state)}
                                required={requiredFields.includes("state")}
                                aria-required={requiredFields.includes("state")}
                                autoComplete="address-level1"
                            >
                                <option value="">{DEFAULT_DROPDOWN_PLACEHOLDER}</option>
                                {Object.entries(groupBy<State>(states, "country")).map(([country, items]) => (
                                    <optgroup key={country} label={country}>
                                        {items.map((item) => (
                                            <option key={item.id} value={item.abbreviation}>
                                                {item.state}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </Form.Select>
                            {fieldErrors?.state && (
                                <Form.Control.Feedback id="state-error" type="invalid">
                                    {fieldErrors.state}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("zip") && (
                        <Form.Group controlId="zip">
                            <Form.Label>
                                Postal code
                                <RequiredFieldIndicator required={requiredFields.includes("zip")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.zip ?? ""}
                                isInvalid={Boolean(fieldErrors?.zip)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("zip", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.zip) ? "zip-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.zip)}
                                required={requiredFields.includes("zip")}
                                aria-required={requiredFields.includes("zip")}
                                autoComplete="postal-code"
                                maxLength={10}
                            />
                            {fieldErrors?.zip && (
                                <Form.Control.Feedback id="zip-error" type="invalid">
                                    {fieldErrors.zip}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}

                    <hr className="my-0"></hr>

                    {!hiddenFields.includes("acct_number") && !hideAcctNumberOnPortal && (
                        <Form.Group controlId="accountNumber">
                            <Form.Label>
                                Account number
                                <RequiredFieldIndicator required={requiredFields.includes("acct_number")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.accountNumber ?? ""}
                                isInvalid={Boolean(fieldErrors?.accountNumber)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("accountNumber", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.accountNumber) ? "accountNumber-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.accountNumber)}
                                required={requiredFields.includes("acct_number")}
                                aria-required={requiredFields.includes("acct_number")}
                                autoComplete="off"
                            />
                            {fieldErrors?.accountNumber && (
                                <Form.Control.Feedback id="accountNumber-error" type="invalid">
                                    {fieldErrors.accountNumber}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("taxid") && (
                        <Form.Group controlId="taxId">
                            <Form.Label>
                                Tax ID
                                <RequiredFieldIndicator required={requiredFields.includes("taxid")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.taxId ?? ""}
                                isInvalid={Boolean(fieldErrors?.taxId)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("taxId", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.taxId) ? "taxId-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.taxId)}
                                required={requiredFields.includes("taxid")}
                                aria-required={requiredFields.includes("taxid")}
                                autoComplete="off"
                            />
                            {fieldErrors?.taxId && (
                                <Form.Control.Feedback id="taxId-error" type="invalid">
                                    {fieldErrors.taxId}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("premiseid") && (
                        <Form.Group controlId="premiseId">
                            <Form.Label>
                                Premise ID
                                <RequiredFieldIndicator required={requiredFields.includes("premiseid")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.premiseId ?? ""}
                                isInvalid={Boolean(fieldErrors?.premiseId)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("premiseId", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.premiseId) ? "premiseId-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.premiseId)}
                                required={requiredFields.includes("premiseid")}
                                aria-required={requiredFields.includes("premiseid")}
                                autoComplete="off"
                            />
                            {fieldErrors?.premiseId && (
                                <Form.Control.Feedback id="premiseId-error" type="invalid">
                                    {fieldErrors.premiseId}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {!hiddenFields.includes("meterid") && (
                        <Form.Group controlId="meterId">
                            <Form.Label>
                                Meter ID
                                <RequiredFieldIndicator required={requiredFields.includes("meterid")} />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={updatedContact.meterId ?? ""}
                                isInvalid={Boolean(fieldErrors?.meterId)}
                                readOnly={isFormReadOnly}
                                onChange={(e) => onChange("meterId", e.target.value)}
                                aria-describedby={Boolean(fieldErrors?.meterId) ? "meterId-error" : undefined}
                                aria-invalid={Boolean(fieldErrors?.meterId)}
                                required={requiredFields.includes("meterid")}
                                aria-required={requiredFields.includes("meterid")}
                                autoComplete="off"
                            />
                            {fieldErrors?.meterId && (
                                <Form.Control.Feedback id="meterId-error" type="invalid">
                                    {fieldErrors.meterId}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}

                    <hr className="my-0"></hr>

                    {showSaveContactSection && (
                        <Form.Group controlId="saveContact">
                            <Form.Check
                                type="checkbox"
                                label="Save this as a new contact in my profile contact list"
                                checked={saveContact}
                                readOnly={isFormReadOnly}
                                onChange={(e) => setSaveContact(e.target.checked)}
                            />
                        </Form.Group>
                    )}

                    <div className="d-flex flex-row gap-3 align-items-end">
                        <SubmitButton type="submit" isSubmitting={isSubmitting} spinnerText="Submitting...">
                            Save
                        </SubmitButton>
                        <Button variant="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

const RequiredFieldIndicator = ({ required }: { required: boolean }) => {
    return required ? <span aria-hidden="true">&thinsp;{"*"}</span> : null;
};

const validateContactData = ({
    contact,
    contactType,
    requirements,
    isCustomerContact,
    isAppSubmitted,
}: {
    contact: ApplicationContact;
    contactType: ContactType;
    requirements: ContactRequirements;
    isCustomerContact: boolean;
    isAppSubmitted: boolean;
}) => {
    let errors = null;
    const requiredFields = isCustomerContact
        ? requirements.requiredFields.map((field: RequiredField) => field.field)
        : getRequiredFieldsByContactType(requirements.requiredFields, contactType);

    /* Validate Premise contact if one of the following is true:
    /  - it is required 
    /  - it submitted app contact
    */

    if (contactType === ContactType.Premise) {
        if (isAppSubmitted || requirements.requirePremise === RequireContact.Required) {
            errors = validateContact(requiredFields, contact);
        }
    }

    if (contactType === ContactType.Primary) {
        if (isAppSubmitted || requirements.requirePrimaryContact === RequireContact.Required) {
            errors = validateContact(requiredFields, contact);
        }
    }

    if (contactType === ContactType.Contractor) {
        // Do not validate the form if it is readonly
        if (
            requirements.requireContractor &&
            [(RequireContact.RequiredFromList, RequireContact.NotRequiredFromList, RequireContact.ReadOnly)].includes(
                requirements.requireContractor
            )
        ) {
            return errors;
        }

        if (isAppSubmitted || requirements.requirePrimaryContact === RequireContact.Required) {
            errors = validateContact(requiredFields, contact);
        }

        // Add error if required contractor is not selected
        if (
            requirements.requireContractor &&
            [RequireContact.RequiredFromList].includes(requirements.requireContractor) &&
            isContactDataEmpty(contact)
        ) {
            errors = set(errors ?? {}, "contractorSearch", "Contractor is Required");
        }
    }

    if (isCustomerContact) {
        errors = validateContact(requiredFields, contact);
    }

    return errors;
};

const validateContact = (requiredFields: string[], contact: ApplicationContact) => {
    let errors: ContactValidationResult = {};

    if (!contact) {
        return errors;
    }

    const validateRequiredField = (fieldKey: keyof ApplicationContact) => {
        if (requiredFields.includes(fieldKey.toLowerCase()) && isEmpty(contact[fieldKey]?.toString().trim())) {
            set(errors, fieldKey, REQUIRED_FIELD_ERROR_TEXT);
        }
    };

    // first name
    if (isEmpty(contact.firstName?.trim()) && isEmpty(contact.company?.trim())) {
        let errorFieldKey = "";

        // Get visible required field key
        if (requiredFields.includes("firstname")) {
            errorFieldKey = "firstName";
        } else if (requiredFields.includes("lastname")) {
            errorFieldKey = "lastName";
        } else {
            errorFieldKey = "company";
        }

        set(errors, errorFieldKey, REQUIRED_LAST_AND_FIRST_OR_CONTACT);
    }

    // last name
    if (isEmpty(contact.lastName?.trim())) {
        if (isEmpty(contact.company?.trim()) && !isEmpty(contact.firstName?.trim())) {
            let errorFieldKey = "";

            // Get visible required field key
            if (requiredFields.includes("lastname")) {
                errorFieldKey = "lastName";
            } else if (requiredFields.includes("firstname")) {
                errorFieldKey = "firstName";
            } else {
                errorFieldKey = "company";
            }

            set(errors, errorFieldKey, REQUIRED_LAST_AND_FIRST_OR_CONTACT);
        }
    }

    validateRequiredField("contactType");
    validateRequiredField("premiseId");
    validateRequiredField("meterId");
    validateRequiredField("accountNumber");

    validateRequiredField("address");
    validateRequiredField("addressCont");
    validateRequiredField("city");
    validateRequiredField("state");
    validateRequiredField("zip");
    validateRequiredField("phone");
    validateRequiredField("cell");
    validateRequiredField("fax");
    validateRequiredField("taxId");
    validateRequiredField("email");

    validateContactDataFormat(contact, errors);

    return errors;
};

const validateContactDataFormat = (contact: ApplicationContact, errors: ContactValidationResult) => {
    if (!contact) {
        return undefined;
    }

    if (!isEmpty(contact.phone) && !isPhoneNumber(contact.phone!)) {
        set(errors, "phone", "Enter phone number in the correct format, like (000) 000-000 or 000-000-0000");
    }

    if (!isEmpty(contact.cell) && !isPhoneNumber(contact.cell!)) {
        set(errors, "cell", "Enter cell phone number in the correct format, like (000) 000-000 or 000-000-0000");
    }

    if (!isEmpty(contact.fax) && !isPhoneNumber(contact.fax!)) {
        set(errors, "fax", "Enter fax number in the correct format, like (000) 000-000 or 000-000-0000");
    }

    if (!isEmpty(contact.email) && !isEmail(contact.email ?? "")) {
        set(errors, "email", "Enter an email address in the correct format, like name@example.com");
    }

    // zip
    if (!isEmpty(contact.zip) && !isPostalCode(contact.zip ?? "")) {
        set(errors, "zip", "Enter a postal code in the correct format, like 00000, 00000-0000, or A0A 0A0");
    }

    return errors;
};

const getContactNameWithAddress = (contact: ApplicationContact) => {
    const fullName = [contact.firstName, contact.lastName].filter((i) => !isEmpty(i)).join(" ");

    const fullAddress = [contact.address, contact.addressCont].filter((i) => !isEmpty(i)).join(" ");

    return [fullName, contact.company, fullAddress].filter((i) => !isEmpty(i)).join(", ");
};

const getContactTitle = (contactType: ContactType, contactTitle: string | undefined, isAdditionalContact: boolean) => {
    if (isAdditionalContact) {
        return contactTitle ?? EMPTY_VALUE;
    }

    switch (contactType) {
        case ContactType.Premise:
            return "Premise contact";
        case ContactType.Primary:
            return "Primary contact";
        case ContactType.Contractor:
            return "Contractor";
        default:
            return EMPTY_VALUE;
    }
};

async function updateAppContact(applicationNumber: string, contactNumber: string, body: ContactData) {
    if (!applicationNumber || !contactNumber) return Promise.reject(new Error("Application number and contact number are required"));

    const url = getUrl(process.env.REACT_APP_APPLICATION_UPDATE_CONTACT_ENDPOINT, { applicationNumber, contactNumber });
    return httpPutAuthorized(url, body);
}

type ContactValidationResult = {
    [key in keyof ApplicationContact]?: string | string[];
};
