import React from "react";
import Form from "react-bootstrap/Form";
import { utils, WidgetProps } from "@rjsf/core";
import WidgetLabel from "./WidgetLabel";
import { DEFAULT_DROPDOWN_PLACEHOLDER } from "components/JsonForm/utils";

const { asNumber, guessType } = utils;

const nums = new Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema: any, value: any) => {
    // "enum" is a reserved word, so only "type" and "items" can be destructured
    const { type, items } = schema;
    if (value === "") {
        return undefined;
    } else if (type === "array" && items && nums.has(items.type)) {
        return value.map(asNumber);
    } else if (type === "boolean") {
        return value === "true";
    } else if (type === "number") {
        return asNumber(value);
    }

    // If type is undefined, but an enum is present, try and infer the type from
    // the enum values
    if (schema.enum) {
        if (schema.enum.every((x: any) => guessType(x) === "number")) {
            return asNumber(value);
        } else if (schema.enum.every((x: any) => guessType(x) === "boolean")) {
            return value === "true";
        }
    }

    return value;
};

export const SelectWidget = ({
    schema,
    id,
    options,
    label,
    required,
    disabled,
    readonly,
    value,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    rawErrors = [],
}: WidgetProps) => {
    const { enumOptions, enumDisabled, emptyItem } = options;

    const emptyValue = multiple ? [] : "";
    const addEmptyItem = emptyItem || (!required && !multiple);
    const emptyItemLabel = placeholder || DEFAULT_DROPDOWN_PLACEHOLDER;
    const selectedValue = value ?? emptyValue;
    const isInvalid = rawErrors && rawErrors.length > 0;

    function getValue(event: React.FocusEvent | React.ChangeEvent | any, multiple: boolean) {
        if (multiple) {
            return [].slice
                .call(event.target.options as any)
                .filter((o: any) => o.selected)
                .map((o: any) => o.value);
        } else {
            return event.target.value;
        }
    }

    return (
        <>
            <WidgetLabel id={id} label={label} schema={schema} required={required} rawErrors={rawErrors} />
            <Form.Select
                id={id}
                value={selectedValue}
                required={required}
                multiple={multiple}
                disabled={disabled || readonly}
                autoFocus={autofocus}
                // placeholder={placeholder} // This is not supported by react-bootstrap Form.Select
                isInvalid={isInvalid}
                aria-describedby={isInvalid ? `${id}-error` : undefined}
                aria-invalid={isInvalid}
                onBlur={
                    onBlur &&
                    ((event) => {
                        const newValue = getValue(event, multiple);
                        onBlur(id, processValue(schema, newValue));
                    })
                }
                onFocus={
                    onFocus &&
                    ((event) => {
                        const newValue = getValue(event, multiple);
                        onFocus(id, processValue(schema, newValue));
                    })
                }
                onChange={(event) => {
                    const newValue = getValue(event, multiple);
                    onChange(processValue(schema, newValue));
                }}
            >
                {addEmptyItem ? <option value={emptyValue}>{emptyItemLabel}</option> : <option hidden></option>}
                {Array.isArray(enumOptions) &&
                    (enumOptions as any).map(({ value, label }: any, i: number) => {
                        const disabled = Array.isArray(enumDisabled) && enumDisabled.indexOf(value) !== -1;
                        return (
                            <option key={i} id={`${id}-${value}`} value={value} disabled={disabled}>
                                {label}
                            </option>
                        );
                    })}
            </Form.Select>
        </>
    );
};

export default SelectWidget;
