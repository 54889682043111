import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ApplicationTab } from "components/App/utils";
import { HeaderTextColor } from "types/SubmittedAppData";
import { WorkFlowTaskModel } from "components/WorkflowTasks/types";
import { useApplicationWorkflow } from "components/WorkflowTasks/useApplicationWorkflow";
import { isTaskCompleted } from "components/WorkflowTasks/utils";
import { debounce, isNil, throttle } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Dropdown, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router";
import { SubmittedAppData } from "types/SubmittedAppData";
import { useResizeDetector } from "react-resize-detector";
import { AppContext } from "components/App/AppContext";
import { Link } from "react-router-dom";

export const AppNavBar = ({
    appData,
    applicationNumber,
    headerTextColor,
    activeTab,
}: {
    appData?: SubmittedAppData;
    applicationNumber: string;
    headerTextColor: HeaderTextColor;
    activeTab: ApplicationTab;
}) => {
    const location = useLocation();
    const [workflow = []] = useApplicationWorkflow(applicationNumber);
    const [navOverflow, setNavOverflow] = useState<NavOverflow>({});
    const navscrollref = useRef<HTMLDivElement>(null);

    const { requirements } = useContext(AppContext);
    const todoTaskCount = workflow.filter((task: WorkFlowTaskModel) => !isTaskCompleted(task)).length;
    const unreadMessageCount = appData?.applicationNotes.filter((n) => n.isUnread).length;

    const getTabLink = (tab: ApplicationTab) => {
        const params = new URLSearchParams(location.search);
        params.set("activeTab", tab);
        return location.pathname + "?" + params.toString();
    };

    const boxShadowClass = useMemo(() => {
        if (headerTextColor === HeaderTextColor.Dark && navOverflow?.right && !navOverflow?.left) {
            return "black-nav-shadow-left";
        } else if (headerTextColor === HeaderTextColor.Dark && !navOverflow?.right && navOverflow?.left) {
            return "black-nav-shadow-right";
        } else if (headerTextColor === HeaderTextColor.Dark && navOverflow?.right && navOverflow?.left) {
            return "black-nav-shadow";
        } else if (headerTextColor === HeaderTextColor.Light && navOverflow?.right && !navOverflow?.left) {
            return "white-nav-shadow-left";
        } else if (headerTextColor === HeaderTextColor.Light && !navOverflow?.right && navOverflow?.left) {
            return "white-nav-shadow-right";
        } else if (headerTextColor === HeaderTextColor.Light && navOverflow?.right && navOverflow?.left) {
            return "white-nav-shadow";
        } else {
            return "";
        }
    }, [headerTextColor, navOverflow?.left, navOverflow?.right]);

    const onSelectTab = (k: ApplicationTab) => {
        document.getElementById(`header-nav-item-${k}`)?.scrollIntoView({ block: "nearest" });
        updateNavOverflow();
    };

    const updateNavOverflow = useCallback(() => {
        const node = navscrollref.current;
        if (node && !isNil(node?.scrollLeft) && !isNil(node?.offsetWidth)) {
            const left = node.scrollLeft > 0;
            const right = Math.ceil(node.scrollLeft + node?.offsetWidth) < node.scrollWidth;
            setNavOverflow({
                left,
                right,
            });
        }
    }, []);

    const { ref } = useResizeDetector({
        handleHeight: false,
        onResize: updateNavOverflow,
    });

    const throttledMobileScroll = useRef(throttle(updateNavOverflow, 30));

    // need to use this approach because Safari doesn't support onscrollend
    // https://stackoverflow.com/a/60833624/7841823
    const onTouchEnd = () => {
        let currentScroll = navscrollref.current?.scrollLeft;
        const testIfScrolling = throttle(() => {
            if (navscrollref.current?.scrollLeft !== currentScroll) {
                currentScroll = navscrollref.current?.scrollLeft;
                window.requestAnimationFrame(testIfScrolling);
            } else {
                updateNavOverflow();
            }
        }, 30);

        window.requestAnimationFrame(testIfScrolling);
    };

    useEffect(() => {
        const node = navscrollref?.current;
        const updateNavOverflowDebounced = debounce(updateNavOverflow, 30);
        const onWheel = (event: any) => {
            if (node && node?.scrollWidth > node?.clientWidth) {
                node.scrollBy({
                    top: 0,
                    left: event.deltaY * 0.5,
                });
                event.preventDefault();
            }
            updateNavOverflowDebounced();
        };
        node?.addEventListener("wheel", onWheel);

        return () => {
            node?.removeEventListener("wheel", onWheel);
        };
    }, [updateNavOverflow]);

    const navTabs: Array<{
        text: string;
        counter?: number;
        key: ApplicationTab;
        icon: IconProp;
        hide?: boolean;
        counterDescription?: string;
    }> = [
        {
            text: "Tasks",
            counter: todoTaskCount,
            counterDescription: "To-do tasks",
            key: ApplicationTab.Tasks,
            icon: ["fal", "list-check"],
        },
        {
            text: "Inbox & Mail",
            counter: unreadMessageCount,
            counterDescription: "Unread messages",
            key: ApplicationTab.Inbox,
            icon: ["fal", "envelope"],
        },
        {
            text: "Equipment",
            key: ApplicationTab.Equipment,
            icon: ["fal", "plug"],
        },
        {
            text: "Forms",
            key: ApplicationTab.Forms,
            icon: ["fal", "pen-field"],
        },
        {
            text: "Files",
            key: ApplicationTab.Files,
            icon: ["fal", "folder"],
        },
        {
            text: "Related Applications",
            key: ApplicationTab.RelatedApplications,
            icon: ["fal", "link-simple"],
            hide: appData?.applicationFlags.hideRelatedApplications,
        },
        {
            text: "Payments",
            key: ApplicationTab.Payments,
            icon: ["fal", "gift"],
            hide: appData?.applicationFlags.hidePayments,
        },
        {
            text: "Contacts",
            key: ApplicationTab.Contacts,
            icon: ["fal", "address-book"],
        },
        ...(requirements.portalDashboardName
            ? [
                  {
                      text: "Report",
                      key: ApplicationTab.Report,
                      icon: ["fal", "chart-line"] as IconProp,
                  },
              ]
            : []),
    ];

    return (
        <div ref={ref} className="application-header-nav-wrapper mb-0 ms-0 ms-md-0 mt-3 d-flex align-items-center position-relative">
            <div className="position-absolute top-0 start-0 end-0 w-100 h-100 pe-5 box-shadow-overlay pe-none">
                <div className={classNames("w-100 h-100", boxShadowClass)}></div>
            </div>

            <Navbar
                role="navigation"
                onTouchEnd={onTouchEnd}
                onTouchMove={() => throttledMobileScroll?.current?.()}
                ref={navscrollref}
                aria-label="Application menu"
                className="overflow-y-hidden overflow-x-auto me-5 pb-0 pt-0 application-header-nav"
            >
                <Navbar.Collapse>
                    <Nav
                        variant="tabs"
                        className="border-bottom-0"
                        activeKey={activeTab}
                        onSelect={(k) => onSelectTab(k as ApplicationTab)}
                    >
                        {navTabs
                            .filter((t) => !t.hide)
                            .map((t) => {
                                return (
                                    <Nav.Item key={t.key}>
                                        <Nav.Link
                                            eventKey={t.key}
                                            id={`header-nav-item-${t.key}`}
                                            aria-current={t.key === activeTab}
                                            as={Link}
                                            to={getTabLink(t.key)}
                                            data-metadata={JSON.stringify({ name: t.text })}
                                        >
                                            <div
                                                className={classNames("me-1 d-flex flex-row align-items-center gap-2", {
                                                    "text-light": headerTextColor === HeaderTextColor.Light && t.key !== activeTab,
                                                    "text-dark": headerTextColor === HeaderTextColor.Dark && t.key !== activeTab,
                                                })}
                                            >
                                                <FontAwesomeIcon icon={t.icon}></FontAwesomeIcon>
                                                {t.text}
                                                {!!t.counter && (
                                                    <Badge className="rounded-circle fs-8 nav-counter-badge">
                                                        {t.counter}
                                                        {t.counterDescription && (
                                                            <span className="visually-hidden">{t.counterDescription}</span>
                                                        )}
                                                    </Badge>
                                                )}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                );
                            })}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {(navOverflow.left || navOverflow.right) && (
                <div className="h-100 d-flex position-absolute align-self-stretch bottom-0 end-0">
                    <Dropdown className="d-flex">
                        <Dropdown.Toggle id="more-tabs" variant="ghost" className="d-flex m-auto" size="sm" aria-label="More tabs">
                            <FontAwesomeIcon icon={["fal", "ellipsis"]} size={"lg"} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {navTabs
                                .filter((t) => !t.hide)
                                .map((t) => {
                                    return (
                                        <Dropdown.Item
                                            key={t.key}
                                            eventKey={t.key}
                                            as={Link}
                                            to={getTabLink(t.key)}
                                            onClick={() => onSelectTab(t.key)}
                                        >
                                            {t.text}
                                        </Dropdown.Item>
                                    );
                                })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

interface NavOverflow {
    left?: boolean;
    right?: boolean;
}
