import { useEffect } from "react";
import { useHistory } from "react-router";
import { isEqualWith, omit, uniqWith } from "lodash";
import { isInIframe } from "components/utils/dom";
import { ApplicationTab, PageLink, createConfig } from "./utils";
import { PortalConfiguration } from "types/PortalConfiguration";
import { Options } from "html-to-image/lib/types";
import { isNullOrWhitespace, validateActivePage } from "components/utils/validation";
import { History } from "history";
import { dispatchEventElementHighlight } from "components/ElementHighlighter";
import { AccessibilityValidationMessage, ValidationMessageImpact } from "types/validation";

const POST_MESSAGE_OPTIONS: WindowPostMessageOptions = {
    targetOrigin: "*",
};

export const useIframeMessaging = (
    config: PortalConfiguration | null | undefined,
    setConfig: React.Dispatch<React.SetStateAction<PortalConfiguration | null | undefined>>
) => {
    const history = useHistory();
    const isIframe = isInIframe();

    useEffect(() => {
        const onMessage = (event: MessageEvent<MessageData>) => {
            const { data, source } = event;

            if (data) {
                switch (data.type) {
                    case "portal-get-config":
                        postConfiguration(source, config);
                        break;
                    case "portal-set-config":
                        {
                            const utilityTemplate = data.payload?.utilityTemplateConfiguration;
                            const programTemplate = data.payload?.programTemplateConfiguration;
                            const programNumber = data.payload?.programNumber;
                            const utilityName = data.payload?.utilityName;
                            const programName = data.payload?.programName;
                            const requirements = {
                                requireAccountNumber: false,
                                allowEquipmentEntry: false,
                                allowEquipmentCopy: false,
                                hidePortalCreateAccount: false,
                                requireEquipmentOnPortal: false,
                                disableUploadFileReq: false,
                                hideAcctNumberOnPortal: false,
                                hideDashboardPayments: false,
                                portalDashboardName: "",
                                portalWizardStepList: [],
                                // Some prescreen fields to render in portal preview
                                prescreenRequiredFields: [
                                    {
                                        requiredField: "acct_number",
                                    },
                                ],
                            };
                            setConfig(
                                createConfig(utilityTemplate, programTemplate, programNumber, utilityName, programName, requirements)
                            );
                        }
                        break;
                    case "portal-navigate":
                        postPageChange(source, data.payload, history);
                        break;
                    case "portal-get-screenshot":
                        postScreenshot(source, data.payload);
                        break;
                    case "portal-validate":
                        postValidationResults(source, data.payload, history);
                        break;
                    case "portal-highlight-element":
                        highlightElement(source, data.payload as HighlightElementPayload, history);
                        break;
                    default:
                        break;
                }
            }
        };

        // Preload the screenshot library only when using iframe
        const loadLibs = async () => {
            await import("html-to-image");
        };

        if (isIframe) {
            window.addEventListener("message", onMessage, false);
            window.parent.postMessage({ type: "portal-ready", payload: { name: window.name } }, "*");
            loadLibs();
        }

        return () => {
            if (isIframe) {
                window.removeEventListener("message", onMessage, false);
            }
        };
    }, [config, history, isIframe, setConfig]);
};

const postConfiguration = (source: MessageEventSource | null, config: PortalConfiguration | null | undefined) => {
    if (!source) {
        return;
    }

    source.postMessage(
        {
            type: "portal-config",
            payload: config,
        },
        POST_MESSAGE_OPTIONS
    );
};

const postScreenshot = (source: MessageEventSource | null, payload?: MessagePayload) => {
    const requestId = payload?.requestId;

    if (!source || !requestId) {
        return;
    }

    const options = omit(payload, "requestId") as Options;

    // Lazy import the library only when first needed
    import("html-to-image").then((module) => {
        module
            .toPng(document.getElementById("root") as HTMLElement, options)
            .then((dataUrl) => {
                source.postMessage(
                    {
                        type: requestId,
                        payload: {
                            dataUrl,
                        },
                    },
                    POST_MESSAGE_OPTIONS
                );
            })
            .catch((error) => {
                console.log(error);
                source.postMessage(
                    {
                        type: requestId,
                        payload: {
                            error,
                        },
                    },
                    POST_MESSAGE_OPTIONS
                );
            });
    });
};

const postValidationResults = async (source: MessageEventSource | null, payload: GenericMessagePayload | undefined, history: History) => {
    const requestId = payload?.requestId;

    if (!source || !requestId) {
        return;
    }

    const loggingEnabled = payload?.loggingEnabled ?? false;

    // Clear any highlighted element
    dispatchEventElementHighlight(null);

    let results: AccessibilityValidationMessage[] = [];

    // Validate all sections if active page is "View Application"
    if (window.location.pathname.includes(PageLink.ApplicationView)) {
        const tabs = Object.values(ApplicationTab);
        for (const tab in tabs) {
            history.push(PageLink.ApplicationView + "?activeTab=" + tabs[tab]);

            // await 100ms to allow tabs to switch
            await new Promise((r) => setTimeout(r, 100));

            await waitForSinnersToDisappear();
            results = results.concat(await validateActivePage(loggingEnabled));
        }

        // take distinct results
        results = uniqWith(results, (obj1, obj2) =>
            isEqualWith(obj1, obj2, (value1, value2, key) => {
                return ["url", "nodes"].includes(key as string) ? true : undefined;
            })
        );
    } else {
        await waitForSinnersToDisappear();
        results = await validateActivePage(loggingEnabled);
    }

    source.postMessage(
        {
            type: requestId,
            payload: {
                results,
            },
        },
        POST_MESSAGE_OPTIONS
    );
};

const waitForSinnersToDisappear = async () => {
    // Wait for spinners to disappear.
    let maxSpinnerWaitTime = 2000;
    while (document.querySelectorAll(".spinner-border").length > 0 && maxSpinnerWaitTime > 0) {
        await new Promise((r) => setTimeout(r, 100));
        maxSpinnerWaitTime -= 100;
    }
};

const postPageChange = async (source: MessageEventSource | null, payload: GenericMessagePayload | undefined, history: History) => {
    const requestId = payload?.requestId;

    if (!source || !requestId) {
        return;
    }

    if (payload.state) {
        history.push(payload.link, payload.state);
    } else {
        history.push(payload.link);
    }

    setTimeout(() => {
        source.postMessage({ type: requestId }, POST_MESSAGE_OPTIONS);
    }, 100);
};

const highlightElement = async (source: MessageEventSource | null, payload: HighlightElementPayload | undefined, history: History) => {
    const requestId = payload?.requestId;

    if (!source || !requestId) {
        return;
    }

    // open page if url is different the current page
    if (!isNullOrWhitespace(payload.url) && !(window.location.pathname + window.location.search).includes(payload.url)) {
        // remove domain part from url.
        const url = payload.url.replace(window.location.origin, "");
        history.push(url);

        // await 100ms to allow tabs to switch
        await new Promise((r) => setTimeout(r, 100));
        await waitForSinnersToDisappear();
    }

    const element = document.querySelector(payload.selector);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }

    // Set element to highlight. If null, it will clear the highlight.
    dispatchEventElementHighlight(element, payload.description, payload.impact);

    // Send response back to parent
    source.postMessage({ type: requestId }, POST_MESSAGE_OPTIONS);
};

interface MessageData {
    type: string;
    payload?: GenericMessagePayload;
}

interface MessagePayload {
    requestId?: string;
}

interface GenericMessagePayload extends MessagePayload {
    [key: string]: any;
}

interface HighlightElementPayload extends MessagePayload {
    selector: string;
    description?: string;
    impact?: ValidationMessageImpact;
    url: string;
}
