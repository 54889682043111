import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { isEmpty, isNil } from "lodash";
import { ErrorSummary } from "components/ErrorSummary";
import { ApplicationControls } from "../ApplicationPage/ApplicationControls";
import { ApplicationForm } from "../ApplicationPage/ApplicationForm";
import { ApplicationPageContainer } from "../ApplicationPage/ApplicationPageContainer";
import { ApplicationPageProcessing } from "../ApplicationPage/ApplicationPageProcessing";
import { getApplicationFormData } from "../ApplicationPage/utils";
import { submitByRefPromise } from "components/JsonForm/utils";
import { getAnonymousFormData, submitAnonymousApplication } from "./utils";
import { PageLink } from "components/App/utils";

// Widgets to remove from the form
const WIDGETS_TO_REMOVE = [
    "applicationcontacts",
    "additionalcontacts",
    "equipmentblock",
    "auditequipmentblock",
    "workflow",
    "generalprocedure",
];

export const AnonymousApplicationPage = () => {
    const location = useLocation();
    const history = useHistory();

    const [applicationForm, setApplicationForm] = useState<{
        configuration: { schema: any; uiSchema: any } | undefined;
        data: any;
    }>({
        configuration: undefined,
        data: undefined,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const applicationFormRef = useRef();

    const pathParts = location.pathname.split("/");
    const applicationNumber = pathParts[2];
    const taskNumber = pathParts[3];
    const codeNumber = pathParts[4];

    const applicationDataLoaded =
        !isNil(applicationNumber) && !isNil(taskNumber) && !isNil(codeNumber) && !isEmpty(applicationForm.configuration);

    const isDisqualificationPage = applicationDataLoaded && applicationForm.configuration?.uiSchema["af:disqualificationPage"] === "Y";

    // Load application form data
    useEffect(() => {
        async function loadForm() {
            try {
                setErrorSummary(undefined);
                // Get application form
                const response = await getAnonymousFormData(applicationNumber, taskNumber, codeNumber);
                setApplicationForm({
                    configuration: response.formConfiguration,
                    data: response.formDetails.fields,
                });
                setIsFormLoaded(false);

                // Set error when form is already filled out
                if (isEmpty(response.formConfiguration)) {
                    setErrorSummary({
                        title: "One-time Link No Longer Available",
                        message: "This one-time link form was previously completed and is no longer available.",
                    });
                }
            } catch (error) {
                setErrorSummary(error);
            } finally {
                setIsLoading(false);
                setIsProcessing(false);
            }
        }

        if (applicationNumber && taskNumber && codeNumber) {
            loadForm();
        }
    }, [applicationNumber, taskNumber, codeNumber]);

    const onFormLoaded = useCallback(() => {
        setIsFormLoaded(true);
    }, []);

    const onContinue = useCallback(async () => {
        try {
            // Validate application form
            await submitByRefPromise(applicationFormRef);

            const configuration = {
                schema: applicationForm.configuration?.schema,
                uiSchema: applicationForm.configuration?.uiSchema,
            };

            const { applicationElements, applicationItems, applicationContacts } = getApplicationFormData(
                applicationFormRef,
                undefined,
                undefined,
                configuration,
                applicationForm.data
            );

            setIsProcessing(true);
            setErrorSummary(undefined);

            await submitAnonymousApplication(applicationNumber, taskNumber, codeNumber, {
                applicationElements,
                applicationItems,
                applicationContacts,
            });

            setIsSubmitted(true);
        } catch (error) {
            setIsProcessing(false);
            setErrorSummary(error);
        }
    }, [
        applicationForm.configuration?.schema,
        applicationForm.configuration?.uiSchema,
        applicationForm.data,
        applicationNumber,
        codeNumber,
        taskNumber,
    ]);

    if (isLoading) {
        return <ApplicationPageContainer>Loading...</ApplicationPageContainer>;
    }

    if (isSubmitted) {
        history.push(PageLink.AnonymousThankYouPage);
    }

    return (
        <>
            {isProcessing && <ApplicationPageProcessing />}
            <ApplicationPageContainer key={`${applicationNumber}-${taskNumber}-${codeNumber}`} hidden={isProcessing}>
                <ErrorSummary errorSummary={errorSummary} />
                {applicationDataLoaded && (
                    <>
                        <ApplicationForm
                            formRef={applicationFormRef}
                            applicationNumber={applicationNumber}
                            configuration={applicationForm.configuration}
                            formData={applicationForm.data}
                            onFormLoaded={onFormLoaded}
                            hidden={!isFormLoaded}
                            removeWidgets={WIDGETS_TO_REMOVE}
                        />
                        {isFormLoaded && (
                            <ApplicationControls
                                isFirstPage
                                isLastPage
                                onContinue={onContinue}
                                isProcessing={isProcessing}
                                isDisqualificationPage={isDisqualificationPage}
                            />
                        )}
                    </>
                )}
            </ApplicationPageContainer>
        </>
    );
};
