import { Heading } from "components/Heading";

export function ApplicationPageContainer({ hidden, children, title }: ApplicationPageContainerProps) {
    return (
        <div className="application-page p-0 container-xxl" hidden={hidden}>
            {title && <Heading className="mb-4">{title}</Heading>}
            {children}
        </div>
    );
}

interface ApplicationPageContainerProps {
    hidden?: boolean;
    children: React.ReactNode;
    title?: string;
}
