import Form from "react-bootstrap/Form";
import { WidgetProps } from "@rjsf/core";
import { extractFileInfo, FileInfo, MAX_FILE_SIZE, MAX_FILE_SIZE_TEXT, processFiles, RejectedFile } from "components/utils/files";
import UploadFile from "components/Widgets/UploadFile";
import { difference } from "lodash";
import { useCallback, useEffect, useState } from "react";
import WidgetLabel from "./WidgetLabel";

const FileWidget = (props: WidgetProps) => {
    const {
        id,
        placeholder,
        required,
        readonly,
        disabled,
        multiple,
        label,
        value,
        autofocus,
        options,
        schema,
        onChange,
        rawErrors = [],
    } = props;

    const [values, setValues] = useState(Array.isArray(value) ? value : [value]);
    const filesInfo: FileInfo[] = extractFileInfo(values);

    const isInvalid = rawErrors && rawErrors.length > 0;

    useEffect(() => {
        setValues(Array.isArray(value) ? value : [value]);
    }, [value]);

    useEffect(() => {
        onChange(values.length > 0 ? values : undefined);

        if (multiple) {
            onChange(values.length > 0 ? values : undefined);
        } else {
            onChange(values.length > 0 ? values[0] : undefined);
        }
    }, [multiple, onChange, values]);

    const _onChange = (event: any) => {
        processFiles(event.target.files).then((filesInfo) => {
            setValues(filesInfo.map((fileInfo) => fileInfo.dataURL));
        });
    };

    let accept = undefined;
    if (options?.accept) {
        accept = String(options.accept);
    }

    return (
        <>
            <WidgetLabel id={id} label={label} schema={schema} required={required} rawErrors={rawErrors} />
            <Form.Control
                id={id}
                placeholder={placeholder}
                autoFocus={autofocus}
                required={required}
                disabled={disabled || readonly}
                type="file"
                defaultValue=""
                onChange={_onChange}
                accept={accept}
                isInvalid={isInvalid}
                aria-describedby={isInvalid ? `${id}-error` : undefined}
                aria-invalid={isInvalid}
            />
            <FilesInfo filesInfo={filesInfo} />
        </>
    );
};

export const DropzoneWidget = (props: WidgetProps) => {
    const { id, required, readonly, disabled, multiple, label, value, options, schema, onChange, rawErrors = [] } = props;

    const [values, setValues] = useState(Array.isArray(value) ? value : [value]);
    const [rejectedMessage, setRejectedMessage] = useState<string>();
    const [rejectedFileList, setRejectedFileList] = useState<string[]>([]);
    const filesInfo: FileInfo[] = extractFileInfo(values);

    useEffect(() => {
        setValues(Array.isArray(value) ? value : [value]);
    }, [value]);

    useEffect(() => {
        onChange(values.length > 0 ? values : undefined);
    }, [values.length, values, multiple, onChange]);

    const onDrop = useCallback(
        (files: File[], rejectedFiles: RejectedFile[]) => {
            const currentFiles = values || [];
            let rejectedMessage: string;
            let filesToShow: string[] = [];
            const allowTypesArray = ((options?.accept as string) ?? "").split(",").map((a) => a.trim());
            const rejectedFilesByExtension = rejectedFiles.filter((f) => !allowTypesArray?.some((extension) => f.name.endsWith(extension)));

            if (rejectedFiles.length) {
                if (rejectedFiles.length > 1 && !multiple) {
                    rejectedMessage = `Only one file is allowed.`;
                } else if (rejectedFiles.filter((f) => f.size > MAX_FILE_SIZE).length === 1) {
                    rejectedMessage = `File exceeds the allowed ${MAX_FILE_SIZE_TEXT} size limit:`;
                    filesToShow = rejectedFiles.filter((f: RejectedFile) => f.size > MAX_FILE_SIZE).map((f) => f.name);
                } else if (rejectedFiles.filter((f) => f.size > MAX_FILE_SIZE).length > 1) {
                    rejectedMessage = `Each of the following files exceeds the allowed ${MAX_FILE_SIZE_TEXT} size limit:`;
                    filesToShow = rejectedFiles.filter((f) => f.size > MAX_FILE_SIZE).map((f) => f.name);
                } else if (rejectedFilesByExtension.length === 1) {
                    rejectedMessage = `Following file has unsupported type: ${rejectedFilesByExtension.map((f) => f.name)}.
                    Only ${options?.accept} file types are accepted`;
                } else if (rejectedFilesByExtension.length > 1) {
                    rejectedMessage = `Following files have unsupported type: ${rejectedFilesByExtension.map((f) => f.name)}.
                    Only ${options?.accept} file types are accepted`;
                } else {
                    rejectedMessage = `rejected ${rejectedFiles.map((f) => f.name).join(", ")}`;
                }
            }

            processFiles(files).then((filesInfo) => {
                // remove files that are already uploaded
                const uniqueFiles = filesInfo.filter((fileInfo: FileInfo) => !currentFiles.includes(fileInfo.dataURL)) as FileInfo[];

                // display a message, if there were any duplicates
                const duplicates = difference(filesInfo, uniqueFiles) as FileInfo[];
                if (duplicates.length > 0) {
                    if (!rejectedMessage) {
                        rejectedMessage = "";
                    }
                    rejectedMessage += "The following files have already been added: ";
                    rejectedMessage += duplicates.map((d) => d.name).join(", ");
                }

                setRejectedFileList(filesToShow);
                setValues(currentFiles.concat(uniqueFiles.map((fileInfo) => fileInfo.dataURL)));
                setRejectedMessage(rejectedMessage);
            });
        },
        [multiple, values, options?.accept]
    );

    const removeFile = useCallback(
        (event: any, fileInfo: FileInfo) => {
            event.preventDefault();
            const currentFiles = values || [];

            setValues(currentFiles.filter((dataURL: string) => dataURL !== fileInfo.dataURL));
            setRejectedMessage(undefined);
        },
        [values]
    );

    let accept = null;
    if (options?.accept) {
        accept = options.accept;
    }

    return (
        <div className="dropzone-widget">
            <WidgetLabel id={id} label={label} schema={schema} required={required} rawErrors={rawErrors} />
            <UploadFile
                //  @ts-ignore
                multiple={multiple}
                disabled={readonly || disabled}
                files={filesInfo}
                removeFile={removeFile}
                accept={accept}
                id={id}
                onDrop={onDrop}
            />
            {rejectedMessage && (
                <>
                    <span>{rejectedMessage}</span>
                    {rejectedFileList && (
                        <ul className="rejected-file-list">
                            {rejectedFileList.map((file: string, index: number) => {
                                return <li key={index}>{file}</li>;
                            })}
                        </ul>
                    )}
                </>
            )}
        </div>
    );
};

function FilesInfo(props: any) {
    const { filesInfo } = props;
    if (filesInfo.length === 0) {
        return null;
    }
    return (
        <ul className="file-info mt-1">
            {filesInfo.map((fileInfo: FileInfo, key: number) => {
                const { name, size, type } = fileInfo;
                return (
                    <li key={key}>
                        <strong className="text-break">{name}</strong> ({type}, {size} bytes)
                    </li>
                );
            })}
        </ul>
    );
}

export default FileWidget;
