import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR, { mutate } from "swr";
import { WorkflowFilterStepType, WorkFlowTaskModel } from "./types";
import { isInIframe } from "components/utils/dom";

export const useApplicationWorkflow = (
    applicationNumber?: string,
    stepType?: WorkflowFilterStepType
): [workflow: WorkFlowTaskModel[] | undefined, isLoading: boolean, error: any] => {
    const url = getApplicationWorkflowUrl(applicationNumber, stepType);
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data && url !== null;

    if (isInIframe()) {
        return [mockedWorkflow, false, null];
    }

    return [data?.workflowList, isLoading, error];
};

export const refreshApplicationWorkflow = (applicationNumber?: string, stepType?: string) => {
    const url = getApplicationWorkflowUrl(applicationNumber, stepType);

    if (url) {
        return mutate(url);
    }

    return Promise.resolve();
};

const getApplicationWorkflowUrl = (applicationNumber?: string, stepType?: string) => {
    const query = stepType ? "?stepType=" + stepType : "";
    return !isInIframe() && applicationNumber
        ? getUrl(process.env.REACT_APP_APPLICATION_WORKFLOW_ENDPOINT, { applicationNumber }) + query
        : null;
};

const mockedWorkflow: WorkFlowTaskModel[] = [
    {
        wfTaskNumber: "A5043C09DB8E446AAA69CFF46D357E53",
        state: "Application Received",
        stepNumber: "bc844f3f-b642-4fe8-837c-d0bc90f2871a",
        stepType: "Perform Task",
        step: "123",
        content: "Task content",
        term: 19,
        customStep: "y",
        dateCreated: "2023-11-15T06:30:54.363",
        dateCompleted: "2023-11-15T06:31:04.977",
        dateRemoved: null,
        dateClosed: null,
        dateDue: "2023-12-04T06:30:54.363",
        taskGroup: "ASSIGNED USERS; CUSTOMER - CONTRACTOR; CUSTOMER - PREMISE; CUSTOMER - PRIMARY",
        userOwner: null,
        userCompleted: null,
        hideFlag: false,
        allowAccess: true,
        isAutomatedTask: false,
        processMode: null,
        statusSortOrder: 1,
        stepSortOrder: 0,
        formPageNumber: null,
        fieldNumber: null,
    },
    {
        wfTaskNumber: "5C1094425CD446628D3995070026EF0E",
        state: "Application Received",
        stepNumber: "67050331-adf3-4a1e-9ba6-224b212d5663",
        stepType: "Perform Task",
        step: "Another task",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
        term: 11,
        customStep: "y",
        dateCreated: "2023-10-24T09:31:02.233",
        dateCompleted: "2023-11-13T09:22:35.4",
        dateRemoved: null,
        dateClosed: null,
        dateDue: "2023-11-04T09:31:02.233",
        taskGroup: "ASSIGNED USERS; CUSTOMER - CONTRACTOR; CUSTOMER - PREMISE; CUSTOMER - PRIMARY; ",
        userOwner: null,
        userCompleted: null,
        hideFlag: false,
        allowAccess: true,
        isAutomatedTask: false,
        processMode: null,
        statusSortOrder: 1,
        stepSortOrder: 0,
        formPageNumber: null,
        fieldNumber: null,
    },
    {
        wfTaskNumber: "29C10B4D4AFD47C78A57AD354E72CEE4",
        state: "Application Received",
        stepNumber: "dc78a19d-5e59-4ed8-872e-43d414eb9d17",
        stepType: "Document Upload",
        step: "Upload a document",
        content: "",
        term: 13,
        customStep: "y",
        dateCreated: "2023-11-09T07:13:31.913",
        dateCompleted: "2023-11-09T07:14:24.76",
        dateRemoved: null,
        dateClosed: null,
        dateDue: "2023-11-22T07:13:31.913",
        taskGroup: "ASSIGNED USERS; CUSTOMER - CONTRACTOR; CUSTOMER - PREMISE; CUSTOMER - PRIMARY; TEST MARCH 20 2023; ",
        userOwner: null,
        userCompleted: null,
        hideFlag: false,
        allowAccess: true,
        isAutomatedTask: false,
        processMode: null,
        statusSortOrder: 1,
        stepSortOrder: 0,
        formPageNumber: null,
        fieldNumber: null,
    },
    {
        wfTaskNumber: "6734C0FE90154C8CB4EDB3F7E4FFE6F6",
        state: "Application Received",
        stepNumber: "8d49ea02-f668-44a8-8366-9ff795035ea4",
        stepType: "Create New Measure",
        step: "equipment task",
        content: "",
        term: 2,
        customStep: "y",
        dateCreated: "2023-12-22T09:01:43.537",
        dateCompleted: null,
        dateRemoved: null,
        dateClosed: null,
        dateDue: "2023-12-24T09:01:43.537",
        taskGroup: "ASSIGNED USERS; CUSTOMER - CONTRACTOR; CUSTOMER - PREMISE; CUSTOMER - PRIMARY; TEST MARCH 20 2023; ",
        userOwner: null,
        userCompleted: null,
        hideFlag: false,
        allowAccess: true,
        isAutomatedTask: false,
        processMode: null,
        statusSortOrder: 1,
        stepSortOrder: 0,
        formPageNumber: null,
        fieldNumber: null,
    },
    {
        wfTaskNumber: "0AA819CD7C854A6FB2389A82EF3EA5CB",
        state: "Application Received",
        stepNumber: "c3606e18-70a6-4b57-8018-3e29f7e9416f",
        stepType: "Application Form Entry Task",
        step: "Form field entry task",
        content: "Content text",
        term: 1,
        customStep: "y",
        dateCreated: "2024-01-25T10:44:43.39",
        dateCompleted: null,
        dateRemoved: null,
        dateClosed: null,
        dateDue: "2024-01-26T10:44:43.39",
        taskGroup: "ASSIGNED USERS; CUSTOMER - CONTRACTOR; CUSTOMER - PREMISE; CUSTOMER - PRIMARY; TEST MARCH 20 2023; ",
        userOwner: null,
        userCompleted: null,
        hideFlag: false,
        allowAccess: true,
        isAutomatedTask: false,
        processMode: null,
        statusSortOrder: 1,
        stepSortOrder: 0,
        formPageNumber: "123456",
        fieldNumber: null,
    },
];
