import { isEmpty } from "lodash";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { PageConfiguration } from "types/PageConfiguration";
import { PageLink } from "components/App/utils";
import { isInIframe } from "./dom";

// flag to indicate if the Google Tag Manager is initialized
let isInitializedGTM = false;
// flag to indicate if the Google Analytics is initialized
let isInitializedGA = false;
// flag to indicate if the page is reloading
let isReloading = false;

/**
 * Initializes the Google Tag Manager, Google Analytics and reloads the page if needed.
 *
 * @param googleTagManagerKey - The Google Tag Manager key.
 * @param googleAnalyticsTrackingId - The Google Analytics tracking ID.
 * @param page - page configuration.
 * @returns Returns true if the initialization is successful, false if reloading is required.
 */
export const initGoogleAnalytics = (googleTagManagerKey: string, googleAnalyticsTrackingId: string, page: PageConfiguration) => {
    // Do nothing and pretend that the initialization is successful if the page is in an iframe
    if (isInIframe()) {
        return true;
    }

    const isAnonymousPage = isAnonymous(page);

    if ((isInitializedGTM || isInitializedGA) && !isAnonymousPage && !isReloading) {
        // set the flag to not call reloading multiple times
        isReloading = true;
        // reload page to disable Google Analytics
        window.location.reload();

        return false;
    }

    if (!isInitializedGTM && isAnonymousPage && !isEmpty(googleTagManagerKey)) {
        // set the flag to not call initialize multiple times
        isInitializedGTM = true;
        // initialize GTM
        TagManager.initialize({
            gtmId: googleTagManagerKey,
        });
    }

    if (!isInitializedGA && isAnonymousPage && !isEmpty(googleAnalyticsTrackingId)) {
        // set the flag to not call initialize multiple times
        isInitializedGA = true;
        // initialize GA
        ReactGA.initialize(googleAnalyticsTrackingId, { legacyDimensionMetric: false });
        // Send the first "pageview" event after initializing GA
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    return true;
};

/**
 * Do not allow tracking anonymous pages and login pages
 * @param page - The page configuration.
 * @returns True if the page is anonymous, false otherwise.
 */
const isAnonymous = (page: PageConfiguration) => {
    const excludedPages = [
        PageLink.SignIn,
        PageLink.CustomerAuthCode,
        PageLink.AccountCreate,
        PageLink.ForgotPassword,
        PageLink.customerEmailAuthCode,
    ];

    return page.isAnonymous && !excludedPages.some((prefix) => page.link.startsWith(prefix));
};
