import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HiddenSubmitButton, JsonFormWithConditionals } from "components/JsonForm";
import {
    addNonExistingValueOptionsToSchema,
    getFormNameFromConfig,
    getFormPageNumber,
    getSchemaIds,
    removeDisabledFields,
    setInitialValuesByFieldNumber,
    transformApplicationFormErrors,
} from "components/JsonForm/utils";
import { ErrorSummary } from "components/ErrorSummary";
import { get, isFinite, isNumber } from "lodash";
import { Spinner } from "react-bootstrap";

export function ApplicationForm(props) {
    const className = props.className || "mb-4";

    const [widgetsLoaded, setWidgetsLoaded] = useState({
        contacts: false,
        additionalContacts: false,
        workflow: false,
    });
    const allWidgetsLoaded = Object.values(widgetsLoaded).every((v) => v === true);

    const applicationNameRef = useRef();

    // Set widgets as loaded if not present in form. Widget not present if ref not initialized.
    useEffect(() => {
        setWidgetsLoaded((prev) => ({
            contacts: props.contactsWidgetRef?.current ? prev.contacts : true,
            additionalContacts: props.additionalContactsWidgetRef?.current ? prev.additionalContacts : true,
            workflow: props.workflowWidgetRef?.current ? prev.workflow : true,
        }));
    }, [props.contactsWidgetRef, props.additionalContactsWidgetRef, props.workflowWidgetRef]);

    // Notify the parent component when form is fully loaded.
    useEffect(() => {
        if (allWidgetsLoaded) {
            props.onFormLoaded?.();
        }
    }, [allWidgetsLoaded, props]);

    const { schema, uiSchema, rules, formData, formFieldCount } = useMemo(() => {
        const formConfig = removeDisabledFields({
            ...props.configuration,
            visibleFieldIds: props.visibleFieldIds,
            removeWidgets: props.removeWidgets,
        });

        const formData = setInitialValuesByFieldNumber(formConfig.schema, formConfig.uiSchema, props.formData);

        const updatedSchema = addNonExistingValueOptionsToSchema({
            schema: formConfig.schema,
            initialValues: formData,
        });

        updateLengthValidation({ schema: updatedSchema, uiSchema: formConfig.uiSchema });

        return { ...formConfig, schema: updatedSchema, formData };
    }, [props.configuration, props.formData, props.removeWidgets, props.visibleFieldIds]);

    const formFieldsNotFound = props.visibleFieldIds?.length > 0 && formFieldCount === 0;

    const formContext = useMemo(
        () => ({
            applicationNumber: props.applicationNumber,
            localizeDateValues: true,
            appSubmitted: props.appSubmitted,
            contactsWidgetRef: props.contactsWidgetRef,
            additionalContactsWidgetRef: props.additionalContactsWidgetRef,
            workflowWidgetRef: props.workflowWidgetRef,
            applicationNameRef,
            onContactsLoaded: () => setWidgetsLoaded((prev) => ({ ...prev, contacts: true })),
            onAdditionalContactsLoaded: () => setWidgetsLoaded((prev) => ({ ...prev, additionalContacts: true })),
            onWorkflowLoaded: () => setWidgetsLoaded((prev) => ({ ...prev, workflow: true })),
            isWorkflowTask: props.isWorkflowTask,
        }),
        [
            props.applicationNumber,
            props.appSubmitted,
            props.contactsWidgetRef,
            props.additionalContactsWidgetRef,
            props.workflowWidgetRef,
            props.isWorkflowTask,
        ]
    );

    const transformErrors = useCallback(
        (errors) => {
            return transformApplicationFormErrors(errors, schema, uiSchema, formContext);
        },
        [schema, uiSchema, formContext]
    );

    if (formFieldsNotFound) {
        return <ErrorSummary errorSummary={{ message: "Form fields not found" }} />;
    }

    return (
        <>
            {props.hidden && (
                <div className="m-auto d-flex flex-column justify-content-center align-items-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading form...</span>
                    </Spinner>
                </div>
            )}
            <div className={className} hidden={props.hidden}>
                <JsonFormWithConditionals
                    idPrefix={getFormPageNumber({ uiSchema })}
                    formRef={props.formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    rules={rules}
                    formData={formData}
                    fieldKey="af:fieldNumber"
                    formContext={formContext}
                    transformErrors={transformErrors}
                    readonly={props.readonly}
                    isApplicationForm
                    buttonClassName={props.buttonClassName}
                    formName={getFormNameFromConfig({ schema, uiSchema })}
                >
                    <HiddenSubmitButton />
                </JsonFormWithConditionals>
            </div>
        </>
    );
}

export const updateLengthValidation = ({ schema, uiSchema }) => {
    getSchemaIds(schema, true).forEach((item) => {
        const schemaId = item.id;
        const schemaPath = `properties.${schemaId.split(".").join(".properties.")}`;
        const fieldUiSchema = get(uiSchema, schemaId);
        let fieldSchema = get(schema, schemaPath);

        if (fieldUiSchema && ["string"].includes(fieldSchema?.type)) {
            const maxLength = fieldUiSchema["af:maxLength"] ? Number(fieldUiSchema["af:maxLength"]) : undefined;

            if (isNumber(maxLength) && isFinite(maxLength) && maxLength > 0) {
                if (fieldSchema.type === "array" && fieldSchema.items) {
                    fieldSchema.items = {
                        ...fieldSchema.items,
                        ...(maxLength ? { maxLength } : {}),
                    };
                } else {
                    fieldSchema = {
                        ...fieldSchema,
                        ...(maxLength ? { maxLength } : {}),
                    };
                }
            }
        }
    });
};
