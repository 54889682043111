import Form, { WidgetProps } from "@rjsf/core";
import { useContactTypes } from "components/utils/useContactTypes";
import { useCustomerContacts } from "components/utils/useCustomerContacts";
import { isEmpty, set, toNumber } from "lodash";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { AdditionalContactType, ContactInboundData, ContactValidationResult } from "../ApplicationContactsWidget/types";
import { ContactType } from "components/utils/contacts";
import { clearApplicationContactsCache, useApplicationContacts } from "../ApplicationContactsWidget/useApplicationContacts";
import {
    validateContact,
    getFormRefValues,
    getContactTypeByName,
    createContactFormDataObject,
    getStoredContactState,
    validateContactDataFormat,
} from "../ApplicationContactsWidget/utils";
import { isContactDataEmpty } from "components/utils/contacts";
import { AdditionalContactForm } from "./AdditionalContactForm";
import { AdditionalContactRequirements } from "./types";
import { useAdditionalContacts } from "./useAdditionalContacts";
import { useApplicationContactRequirements } from "../ApplicationContactsWidget/useApplicationContactRequirements";
import { useContractorLabel } from "../ApplicationContactsWidget/useContractorLabel";

export const AdditionalContactsWidget = (props: WidgetProps) => {
    const { applicationNumber, additionalContactsWidgetRef, onAdditionalContactsLoaded, appSubmitted } = props.formContext ?? {};
    const [additionalContacts, isLoadingAdditionalContacts] = useAdditionalContacts(applicationNumber);
    const [contacts, isLoadingContacts] = useApplicationContacts(applicationNumber);
    const [customerContacts = [], isLoadingCustomerContacts] = useCustomerContacts(1, 100);
    const [contactTypes = [], isLoadingContactTypes] = useContactTypes();
    const [requirements] = useApplicationContactRequirements(applicationNumber);
    const contactFormsRef = useRef<Form<any>[]>([]);

    const contractorLabel = useContractorLabel();

    const [contactFormsData, setContactFormsData] = useState<any[]>([]);

    const [contactErrors, setContactErrors] = useState<ContactValidationResult[]>([]);

    const isLoading = isLoadingAdditionalContacts || isLoadingContacts || isLoadingContactTypes || isLoadingCustomerContacts;

    // Setup widget
    useEffect(() => {
        if (additionalContactsWidgetRef) {
            additionalContactsWidgetRef.current = {
                validate: () => {
                    const errors = validateAdditionalContacts(
                        contactFormsRef,
                        additionalContacts,
                        requirements.disableAddlContactValidation ?? false,
                        contractorLabel
                    );
                    setContactErrors(errors);
                    return !errors.every(isEmpty);
                },
                validateDataFormat: () => {
                    const errors = validateAdditionalContactsDataFormat(contactFormsRef, additionalContacts);
                    setContactErrors(errors);
                    return !errors.every(isEmpty);
                },
                getSubmitValues: () => {
                    const foundContacts: string[] = [];
                    return (
                        additionalContacts
                            .map((requirements, index) => {
                                const formData = getFormRefValues(contactFormsRef.current[index]);
                                const contact = contacts?.find(
                                    (c) => c.contactTitle === requirements.title && !foundContacts.includes(c.contactNumber)
                                );
                                foundContacts.push(contact?.contactNumber ?? "");
                                const contactType = getContactTypeByName(contactTypes, ContactType.Other) as number;
                                const workflowTargetGroupId =
                                    contact?.workflowTargetGroupId ?? requirements.workflowTargetGroupId
                                        ? Number(requirements.workflowTargetGroupId)
                                        : undefined;

                                // Add contact if form has at least some data filled
                                if (Object.values(formData ?? {}).every(isEmpty) && !contact?.contactNumber) {
                                    return undefined;
                                }

                                return {
                                    ...formData,
                                    contactNumber: contact?.contactNumber,
                                    workflowTargetGroupId,
                                    applicationContactType: ContactType.Other,
                                    contactType,
                                    contactTitle: requirements.title,
                                    ...(formData.additionalContactType === AdditionalContactType.Contractor
                                        ? { origId: toNumber(formData.origId) }
                                        : {}),
                                };
                            })
                            // Take only filled contacts
                            .filter((c) => c)
                    );
                },
                fieldNumber: props.uiSchema["af:fieldNumber"],
            };
        }

        return () => {
            if (additionalContactsWidgetRef?.current) {
                additionalContactsWidgetRef.current = undefined;
            }
        };
    }, [
        additionalContactsWidgetRef,
        props.uiSchema,
        additionalContacts,
        contacts,
        contactTypes,
        requirements.disableAddlContactValidation,
        contractorLabel,
    ]);

    // Init contact data
    useEffect(() => {
        if (contacts && additionalContacts) {
            setContactFormsData(() => {
                const foundContacts: string[] = [];
                return additionalContacts.map((requirements) => {
                    const contact = contacts.find((c) => c.contactTitle === requirements.title && !foundContacts.includes(c.contactNumber));
                    foundContacts.push(contact?.contactNumber ?? "");
                    return { ...createContactFormDataObject(contact), origId: contact?.origId };
                });
            });
        }
    }, [additionalContacts, contacts]);

    useEffect(() => {
        if (!isLoading) {
            onAdditionalContactsLoaded?.();
        }
    }, [isLoading, onAdditionalContactsLoaded]);

    // Clear contact data on unmount.
    useEffect(() => {
        return () => {
            clearApplicationContactsCache(applicationNumber);
        };
    }, [applicationNumber]);

    const onUseCustomerContact = useCallback((selectedContact: ContactInboundData | undefined, index: number) => {
        if (selectedContact) {
            const formData = createContactFormDataObject(selectedContact);

            setContactFormsData((prev) => {
                return prev.map((c, i) => {
                    if (i === index) {
                        return { ...formData, storedContactNumber: selectedContact.contactNumber };
                    }

                    return c;
                });
            });
        }
    }, []);

    const onChange = useCallback(
        (form: any, index: number) => {
            const { formData } = form;
            const storedContactNumber = getStoredContactState(formData, customerContacts);
            if (formData.storedContactNumber !== storedContactNumber) {
                setContactFormsData((prev) => {
                    return prev.map((c, i) => {
                        if (i === index) {
                            return { ...formData, storedContactNumber: undefined };
                        }
                        return c;
                    });
                });
            }
        },
        [customerContacts]
    );

    const onClearAdditionalContact = useCallback((index: number) => {
        setContactFormsData((prev) => {
            return prev.map((c, i) => {
                if (i === index) {
                    return {};
                }
                return c;
            });
        });
    }, []);

    // Init Ref callback
    const initRef = useCallback((index: number) => (ref: Form<any>) => (contactFormsRef.current[index] = ref), []);

    if (isLoading || props.readonly || appSubmitted) {
        return null;
    }

    return (
        <div className="additional-contacts-widget border">
            {additionalContacts.map((item, index) => (
                <AdditionalContactForm
                    key={index}
                    index={index}
                    formRef={initRef(index)}
                    formRefObject={contactFormsRef.current[index]}
                    formData={contactFormsData[index]}
                    requirements={item}
                    fullContactRequirements={requirements}
                    extraErrors={contactErrors[index]}
                    onChange={(form) => onChange(form, index)}
                    onClearForm={() => onClearAdditionalContact(index)}
                    applicationNumber={applicationNumber}
                    onUseCustomerContact={(contact) => onUseCustomerContact(contact, index)}
                />
            ))}
        </div>
    );
};

const validateAdditionalContacts = (
    contactFormsRef: MutableRefObject<Form<any>[] | undefined>,
    requirements: AdditionalContactRequirements[],
    disableContactValidation: boolean,
    contractorLabel: string
): ContactValidationResult[] => {
    const errors = requirements.map((item, index) => {
        // hard code F,L,C becuase requiredFields for addi contact will never come from api
        const requiredFields = item.requiredFields?.split(",").map((f) => f.trim().toLowerCase()) ?? ["firstname", "lastname", "company"];
        const showFields = item.showFieldNames?.split(",").map((f) => f.trim().toLowerCase()) ?? [];
        const visibleRequiredFields = requiredFields.filter((f) => showFields.includes(f));
        const contactData = getFormRefValues(contactFormsRef.current?.[index]);

        if (
            (disableContactValidation && isContactDataEmpty(contactData)) ||
            (contactData._contactType === AdditionalContactType.Contractor &&
                (!isContactDataEmpty(contactData) || disableContactValidation))
        ) {
            return [];
        }
        let errors;

        if (contactData._contactType === AdditionalContactType.Contractor) {
            // Add error if required additional contractor contact is not selected
            if (isContactDataEmpty(contactData)) {
                errors = {};
                set(errors, "contractorSearch.__errors[0]", `${contractorLabel} is Required`);
            }
        } else {
            errors = validateContact(visibleRequiredFields, contactData);
            // Set error if form is not filled but contact is required
            if (!errors && item.allAdditionalContactsAreRequired === "TRUE" && Object.values(contactData ?? {}).every(isEmpty)) {
                errors = {};
                set(errors, "section.__errors[0]", "Contact is required");
            }
        }

        return errors;
    });

    return errors;
};

const validateAdditionalContactsDataFormat = (
    contactFormsRef: MutableRefObject<Form<any>[] | undefined>,
    requirements: AdditionalContactRequirements[]
): ContactValidationResult[] => {
    const errors = requirements.map((item, index) => {
        const contactData = getFormRefValues(contactFormsRef.current?.[index]);
        return validateContactDataFormat(contactData);
    });

    return errors;
};
