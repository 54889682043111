import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import cn from "classnames";
import { QuickLink } from "./QuickLink";
import { Container } from "components/Layout/Container";
import { PageLink } from "components/App/utils";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { useUserAuthenticated } from "components/utils";
import { isInIframe } from "components/utils/dom";
import { Collapse } from "react-bootstrap";

import "./styles.scss";

export function HeaderMenu() {
    const [isExpanded, setIsExpanded] = useState(false);

    const isUserAuthenticated = useUserAuthenticated();
    const location = useLocation();

    const showHeader = useMemo(
        () =>
            isUserAuthenticated ||
            (isInIframe() && (location.pathname.includes(PageLink.CustomerHome) || location.pathname.includes(PageLink.ApplicationView))),
        [isUserAuthenticated, location]
    );

    if (!showHeader) {
        return null;
    }

    return (
        <nav className="header-menu">
            <Container className="header-menu-links d-flex flex-row justify-content-between">
                <div className="align-self-center">
                    <Link className="home-link menu-link" to={PageLink.CustomerHome}>
                        <FontAwesomeIcon icon={"house"} />
                        Home
                    </Link>
                </div>
                <div className="header-quick-links d-none d-md-flex flex-row justify-content-end align-items-center">
                    {quickLinks.map((item, index) => (
                        <QuickLink key={index} text={item.text} href={item.href} icon={item.icon} iconClassName={item.iconClassName} />
                    ))}
                </div>
                <div
                    className={cn("quick-links-btn d-flex d-md-none align-items-center ", { expanded: isExpanded })}
                    onClick={() => setIsExpanded(!isExpanded)}
                    role="button"
                    tabIndex={0}
                    aria-controls="quick-links-list"
                    aria-expanded={isExpanded}
                >
                    Quick Links
                    {!isExpanded ? <FontAwesomeIcon icon={"chevron-down"} /> : <FontAwesomeIcon icon={"chevron-up"} />}
                </div>
            </Container>
            <Collapse in={isExpanded}>
                <div id="quick-links-list">
                    <Container className="header-quick-links d-md-none">
                        {quickLinks.map((item, index) => (
                            <QuickLink key={index} text={item.text} href={item.href} icon={item.icon} iconClassName={item.iconClassName} />
                        ))}
                    </Container>
                </div>
            </Collapse>
        </nav>
    );
}

const quickLinks: {
    text: string;
    href: string;
    icon?: IconName;
    iconClassName?: string;
}[] = [
    {
        text: "Contacts",
        href: PageLink.Contacts,
    },
    {
        text: "My profile",
        href: PageLink.AccountDetails,
    },
    {
        text: "Change password",
        href: PageLink.ChangePassword,
    },
    {
        text: "Change email",
        href: PageLink.ChangeEmail,
    },
    {
        text: "Logout",
        href: PageLink.SignOut,
        icon: "right-from-bracket",
        iconClassName: "d-block",
    },
];
