import { Button } from "components/Button";
import { ContactCard } from "components/ContactCard";
import { Icon } from "components/Icon";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { SubmittedAppData } from "../../../types/SubmittedAppData";
import { ContactRequirements } from "types/ContactRequirements";
import { ContactTypeCode } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { isEmpty } from "lodash";
import { ApplicationContact } from "components/utils/contacts";

const ADDITIONAL_CONTACTS_LIST_ID = "additional-contacts-list";

export const EmptyApplicationContact = {
    contactNumber: "",
    contactType: "",
    contactTitle: "",
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    addressCont: "",
    city: "",
    state: "",
    zip: "",
    accountNumber: "",
    phone: "",
    cell: "",
    fax: "",
    email: "",
    premiseId: "",
    taxId: "",
    meterId: "",
    origId: "",
    storedContactNumber: "",
};

export const ApplicationContacts = ({ applicationNumber, appData, contactRequirements, onContactChanged }: ApplicationContactsProps) => {
    const [isAdditionalContactsExpanded, setIsAdditionalContactsExpanded] = useState(true);

    const appFlags = appData.applicationFlags;

    const premiseContact =
        appData.applicationContacts.find((c) => c.contactNumber === appData?.premiseContactNumber) ?? EmptyApplicationContact;
    const primaryContact =
        appData.applicationContacts.find((c) => c.contactNumber === appData?.primaryContactNumber) ?? EmptyApplicationContact;
    const contractorContact =
        appData.applicationContacts.find((c) => c.contactNumber === appData?.contractorContactNumber) ?? EmptyApplicationContact;

    const canEditPremiseContact = !appFlags.isApplicationLocked && appFlags.isEditablePremiseContact;
    const canEditPrimaryContact = !appFlags.isApplicationLocked && appFlags.isEditablePrimaryContact;
    const canEditContractorContact = !appFlags.isApplicationLocked && appFlags.isEditableContractorContact;

    const isContactEmpty = (c: ApplicationContact) => {
        return Object.keys(c)
            .filter((k) => !["contactNumber", "contactTitle", "contactType", "contactTypeDesc"].includes(k))
            .every((k) => isEmpty(c[k as keyof ApplicationContact]));
    };

    const additionalContacts = appData.applicationContacts
        .filter((c) => !isContactEmpty(c))
        .filter(
            (c) => ![appData.premiseContactNumber, appData.primaryContactNumber, appData.contractorContactNumber].includes(c.contactNumber)
        );

    return (
        <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-3">
                <h2 className="m-0">Main Contacts</h2>
                <div className="application-contacts-list d-grid gap-4">
                    <ContactCard
                        contact={premiseContact}
                        contactTypeCode={ContactTypeCode.Premise}
                        applicationNumber={applicationNumber}
                        isEditable={canEditPremiseContact}
                        requirements={contactRequirements}
                        isAppSubmitted={true}
                        onContactChanged={onContactChanged}
                    />
                    <ContactCard
                        contact={primaryContact}
                        contactTypeCode={ContactTypeCode.Primary}
                        applicationNumber={applicationNumber}
                        isEditable={canEditPrimaryContact}
                        requirements={contactRequirements}
                        isAppSubmitted={true}
                        onContactChanged={onContactChanged}
                    />
                    <ContactCard
                        contact={contractorContact}
                        contactTypeCode={ContactTypeCode.Contractor}
                        applicationNumber={applicationNumber}
                        isEditable={canEditContractorContact}
                        requirements={contactRequirements}
                        isAppSubmitted={true}
                        onContactChanged={onContactChanged}
                    />
                </div>
            </div>
            {additionalContacts.length > 0 && (
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row gap-2 align-items-center">
                        <Button
                            variant="link"
                            className="text-body"
                            aria-expanded={isAdditionalContactsExpanded}
                            aria-controls={ADDITIONAL_CONTACTS_LIST_ID}
                            aria-label={isAdditionalContactsExpanded ? "Hide additional contacts" : "Show additional contacts"}
                            onClick={() => setIsAdditionalContactsExpanded(!isAdditionalContactsExpanded)}
                        >
                            <Icon icon={isAdditionalContactsExpanded ? "chevron-down" : "chevron-up"} />
                        </Button>
                        <h2 className="m-0">Additional Contacts</h2>
                    </div>
                    <div className="p-2" />
                    <Collapse in={isAdditionalContactsExpanded}>
                        <div id={ADDITIONAL_CONTACTS_LIST_ID}>
                            <div className="application-contacts-list d-grid gap-4">
                                {additionalContacts.map((c) => (
                                    <ContactCard
                                        key={c.contactNumber}
                                        contact={c}
                                        applicationNumber={applicationNumber}
                                        requirements={contactRequirements}
                                        isAdditionalContact
                                        isAppSubmitted={true}
                                        onContactChanged={onContactChanged}
                                    />
                                ))}
                            </div>
                        </div>
                    </Collapse>
                </div>
            )}
        </div>
    );
};

interface ApplicationContactsProps {
    applicationNumber: string;
    appData: SubmittedAppData;
    contactRequirements: ContactRequirements;
    onContactChanged?: () => void;
}
