import { WidgetProps } from "@rjsf/core";
import WidgetLabel from "./WidgetLabel";

export const ReadOnlyValueWidget = (props: WidgetProps) => {
    const { id, label, schema, required, rawErrors, uiSchema } = props;

    const isReadOnlyValue = uiSchema["ui:widget"] === "readonlyvalue" && label;
    return (
        <>
            {isReadOnlyValue && (
                <>
                    <WidgetLabel id={id} label={label} schema={schema} required={required} rawErrors={rawErrors} />
                    <br />
                </>
            )}
            <span dangerouslySetInnerHTML={{ __html: props.value }} className={props.className} />
        </>
    );
};
